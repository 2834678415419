.kpi-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
  }
  
  .kpi-box-wrapper {
    flex: 1 1 calc(20% - 1rem);
    min-width: 200px;
  }
  
  @media (max-width: 1200px) {
    .kpi-box-wrapper {
      flex: 1 1 calc(25% - 1rem);
    }
  }
  
  @media (max-width: 992px) {
    .kpi-box-wrapper {
      flex: 1 1 calc(33.333% - 1rem);
    }
  }
  
  @media (max-width: 768px) {
    .kpi-box-wrapper {
      flex: 1 1 calc(50% - 1rem);
    }
  }
  
  @media (max-width: 576px) {
    .kpi-box-wrapper {
      flex: 1 1 100%;
    }
  }