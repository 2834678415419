/* General Styles */
body {
    font-family: sans-serif;
    margin: 0;
    padding: 0;
    color: #333;
    line-height: 1.6;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

h1, h2 {
    font-weight: bold;
    margin-bottom: 20px;
}

a {
    text-decoration: none;
    color: inherit;
}
img {
  max-width: 100%;
  height: auto;
  display: block;
}
/* Header */
header {
    background: #f0f7ff;
    padding: 50px 0;
    text-align: center;
}
header h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
}
header p.subheadline {
    font-size: 1.2em;
    color: #555;
    margin-bottom: 20px;
}

.cta-button {
    display: inline-block;
    padding: 15px 30px;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.cta-button:hover {
    background-color: #0056b3;
}

.trust-signal {
    font-size: 0.9em;
    color: #777;
    margin-top: 10px;
}


/* Problem Section */
.problem {
    padding: 50px 0;
}

.problem h2 {
    text-align: center;
    margin-bottom: 30px;
}

.problem-points {
    max-width: 700px;
    margin: 0 auto;
    text-align: center;
}

.problem-points p {
    margin-bottom: 10px;
}


/* Solution Section */
.solution {
    background: #f9f9f9;
    padding: 50px 0;
}

.solution h2 {
    text-align: center;
    margin-bottom: 30px;
}

.solution-benefits {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.solution-benefits > div {
    flex: 1 1 250px;
    text-align: center;
    padding: 20px;
    border: 1px solid #eee;
    background: white;
    border-radius: 8px;
}
.solution-benefits > div img {
    max-width: 80px;
    margin-bottom: 10px;
}

/* Why Kwiqly Section */
.why-kwiqly {
    padding: 50px 0;
    text-align: center;
}

/* How It Works */

.how-it-works {
  padding: 50px 0;
  background: #f0f7ff;
}
.how-it-works h2 {
  text-align: center;
  margin-bottom: 30px;
}

.process {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  align-items: flex-start;
}

.process > div {
  flex: 1 1 200px;
  text-align: center;
  padding: 20px;
  border: 1px solid #eee;
  background: white;
  border-radius: 8px;
  position: relative;
}

.process > div span{
  position: absolute;
  top: -10px;
  left: 10px;
  background: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 50%;
}
.process > div img {
    max-width: 80px;
    margin-bottom: 10px;
}
/* Testimonials */
.testimonials {
  padding: 50px 0;
  background: #f9f9f9;
}

.testimonials h2 {
  text-align: center;
  margin-bottom: 30px;
}

.testimonial-card {
   max-width: 600px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
    background: white;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
.testimonial-card img {
 max-width: 100px;
 margin: 0 auto 10px;
 border-radius: 50%;
}
/* CTA Section */
.cta {
    background: #007bff;
    color: white;
    text-align: center;
    padding: 50px 0;
}

.cta h2 {
    margin-bottom: 30px;
}

.cta p {
    margin-top: 10px;
    font-size: 0.9em;
    opacity: 0.8;
}

/* Footer */
footer {
    background: #333;
    color: white;
    padding: 20px 0;
    text-align: center;
}

footer ul {
    list-style: none;
    padding: 0;
    margin-top: 10px;
}

footer ul li {
    display: inline;
    margin: 0 10px;
}

/* Landing Page Styles */

.landing-container {
  min-height: 100vh;
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
  color: #2d3748;
}

.landing-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  color: #2d3748;
}

.logo svg {
  color: #4299e1;
}

.nav-links {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-link {
  color: #4a5568;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s;
}

.nav-link:hover {
  color: #2b6cb0;
}

.nav-button {
  background: #4299e1;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 0.375rem;
  text-decoration: none;
  font-weight: 500;
  transition: background-color 0.2s;
}

.nav-button:hover {
  background: #2b6cb0;
}

.hero-section {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  text-align: center;
}

.hero-section h1 {
  font-size: 3rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  background: linear-gradient(135deg, #2d3748 0%, #4a5568 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-section > p {
  font-size: 1.25rem;
  color: #4a5568;
  margin-bottom: 3rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin: 4rem 0;
}

.feature-card {
  background: white;
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.feature-card:hover {
  transform: translateY(-5px);
}

.feature-card svg {
  color: #4299e1;
  margin-bottom: 1rem;
}

.feature-card h3 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: #2d3748;
}

.feature-card p {
  color: #4a5568;
  line-height: 1.6;
}

.cta-section {
  margin: 4rem 0;
}

.cta-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background: #4299e1;
  color: white;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  text-decoration: none;
  font-weight: 600;
  transition: background-color 0.2s;
}

.cta-button:hover {
  background: #2b6cb0;
}

.cta-button svg {
  transition: transform 0.2s;
}

.cta-button:hover svg {
  transform: translateX(4px);
}

.landing-footer {
  background: white;
  padding: 2rem;
  margin-top: 4rem;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-section {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #4a5568;
}

.footer-section svg {
  color: #4299e1;
}

/* Responsive Design */
@media (max-width: 768px) {
    header h1 {
        font-size: 2em;
    }

    header p.subheadline {
        font-size: 1em;
    }

    .solution-benefits {
        flex-direction: column;
        align-items: center;
    }

    .process > div {
     flex: 1 1 80%;
    }
}

@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2rem;
  }

  .features-grid {
    grid-template-columns: 1fr;
  }

  .nav-links {
    gap: 1rem;
  }
}