.modern-landing {
  background: linear-gradient(to bottom, #000000, #1a1a1a);
  color: #ffffff;
  min-height: 100vh;
  overflow-x: hidden;
}

.modern-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  z-index: 1000;
}

.nav-links {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-link {
  color: #ffffff;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #4299e1;
}

.nav-button {
  background: #4299e1;
  color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 0.375rem;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
}

.nav-button:hover {
  background: #2b6cb0;
  transform: translateY(-2px);
}

.hero-section {
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: 6rem 2rem;
  position: relative;
}

.hero-content {
  max-width: 600px;
  margin-right: 2rem;
}

.hero-content h1 {
  font-size: 3.5rem;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  background: linear-gradient(135deg, #ffffff 0%, #4299e1 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-content p {
  font-size: 1.25rem;
  color: #a0aec0;
  margin-bottom: 2rem;
}

.hero-visual {
  flex: 1;
  position: relative;
  height: 500px;
  transform: translateZ(0);
  transition: transform 0.1s ease-out;
  will-change: transform;
}

.visual-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.data-visualization {
  position: relative;
  width: 100%;
  height: 100%;
}

.data-point {
  position: absolute;
  width: 8px;
  height: 8px;
  background: #4299e1;
  border-radius: 50%;
  animation: pulseEnhanced 3s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

.data-line {
  position: absolute;
  height: 2px;
  background: linear-gradient(90deg, transparent, #4299e1, transparent);
  animation: flowEnhanced 4s infinite cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes pulseEnhanced {
  0% { transform: scale(1); opacity: 1; box-shadow: 0 0 0 0 rgba(66, 153, 225, 0.4); }
  50% { transform: scale(1.5); opacity: 0.7; box-shadow: 0 0 0 10px rgba(66, 153, 225, 0); }
  100% { transform: scale(1); opacity: 1; box-shadow: 0 0 0 0 rgba(66, 153, 225, 0); }
}

@keyframes flowEnhanced {
  0% { width: 0; opacity: 0; transform: translateX(-100%); }
  50% { width: 100%; opacity: 1; transform: translateX(0); }
  100% { width: 0; opacity: 0; transform: translateX(100%); }
}

.features-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 4rem 2rem;
  background: rgba(255, 255, 255, 0.02);
}

.feature {
  padding: 2rem;
  border-radius: 1rem;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  transition: transform 0.3s ease;
}

.feature:hover {
  transform: translateY(-10px) scale(1.02);
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.feature-icon {
  margin-bottom: 1.5rem;
}

.icon-circle {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: rgba(66, 153, 225, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4299e1;
}

.feature h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #ffffff;
}

.feature p {
  color: #a0aec0;
  line-height: 1.6;
}

.stats-section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 2rem;
  padding: 4rem 2rem;
  text-align: center;
}

.stat h4 {
  font-size: 2.5rem;
  color: #4299e1;
  margin-bottom: 0.5rem;
}

.stat p {
  color: #a0aec0;
}

.tech-section {
  padding: 4rem 2rem;
  background: rgba(255, 255, 255, 0.02);
}

.tech-content {
  max-width: 1200px;
  margin: 0 auto;
}

.tech-content h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.tech-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.tech-item {
  text-align: center;
  opacity: 0;
  transform: translateY(20px) scale(0.95);
  transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.tech-item.fade-in-visible {
  opacity: 1;
  transform: translateY(0) scale(1);
}

.tech-item:hover .tech-icon {
  transform: scale(1.1) rotate(5deg);
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.tech-icon {
  width: 64px;
  height: 64px;
  background: rgba(66, 153, 225, 0.2);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1rem;
  font-weight: bold;
  color: #4299e1;
}

.modern-footer {
  padding: 2rem;
  background: rgba(0, 0, 0, 0.5);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-links {
  display: flex;
  gap: 2rem;
}

.footer-links a {
  color: #a0aec0;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #4299e1;
}

/* Enhanced Animations */
.fade-in {
  opacity: 0;
  transform: translateY(30px) scale(0.95);
  transition: opacity 0.8s cubic-bezier(0.4, 0, 0.2, 1),
              transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: transform, opacity;
}

.fade-in-visible {
  opacity: 1;
  transform: translateY(0) scale(1);
}

/* Different animation variations */
.fade-in[data-animation="left"] {
  transform: translateX(-30px) scale(0.95);
}

.fade-in[data-animation="right"] {
  transform: translateX(30px) scale(0.95);
}

.fade-in[data-animation="zoom"] {
  transform: scale(0.9);
}

.fade-in-visible[data-animation="left"],
.fade-in-visible[data-animation="right"] {
  transform: translateX(0) scale(1);
}

.fade-in-visible[data-animation="zoom"] {
  transform: scale(1);
}

/* Staggered delays for features */
.features-section .feature:nth-child(1) {
  transition-delay: 0.1s;
}

.features-section .feature:nth-child(2) {
  transition-delay: 0.2s;
}

.features-section .feature:nth-child(3) {
  transition-delay: 0.3s;
}

/* Stats animation */
.stats-section .stat {
  opacity: 0;
  transform: translateY(20px) scale(0.95);
  transition: opacity 0.6s cubic-bezier(0.4, 0, 0.2, 1),
              transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.stats-section .stat.fade-in-visible {
  opacity: 1;
  transform: translateY(0) scale(1);
}

.stats-section .stat:nth-child(1) {
  transition-delay: 0.1s;
}

.stats-section .stat:nth-child(2) {
  transition-delay: 0.2s;
}

.stats-section .stat:nth-child(3) {
  transition-delay: 0.3s;
}

/* Tech grid animations */
.tech-grid .tech-item:nth-child(1) {
  transition-delay: 0.1s;
}

.tech-grid .tech-item:nth-child(2) {
  transition-delay: 0.2s;
}

.tech-grid .tech-item:nth-child(3) {
  transition-delay: 0.3s;
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-section {
    flex-direction: column;
    text-align: center;
    padding: 4rem 1rem;
  }

  .hero-content {
    margin-right: 0;
    margin-bottom: 2rem;
  }

  .hero-content h1 {
    font-size: 2.5rem;
  }

  .hero-visual {
    height: 300px;
  }

  .features-section {
    grid-template-columns: 1fr;
  }

  .stats-section {
    grid-template-columns: 1fr;
  }

  .footer-content {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
}

/* Data visualization positioning */
.data-point:nth-child(1) { top: 20%; left: 30%; }
.data-point:nth-child(2) { top: 50%; left: 60%; }
.data-point:nth-child(3) { top: 80%; left: 40%; }

.data-line:nth-child(4) { top: 30%; left: 0; }
.data-line:nth-child(5) { top: 70%; left: 0; }
