.kpi-box {
  background-color: #ffffff;
  border-left: 4px solid;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.kpi-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.kpi-content {
  display: flex;
  align-items: center;
}

.kpi-icon {
  font-size: 2.5rem;
  margin-right: 15px;
}

.kpi-info {
  flex-grow: 1;
}

.kpi-title {
  font-size: 0.9rem;
  font-weight: 600;
  color: #6c757d;
  margin: 0 0 5px 0;
  text-transform: uppercase;
}

.kpi-value {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  margin-bottom: 5px;
}

/* PrimeReact Tooltip customization */
.p-tooltip {
  opacity: 1;
}

.p-tooltip .p-tooltip-text {
  background: #2c3e50;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.875rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
